/* Globals */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Courier New', monospace;
}

body {
  position: relative;
  background-image: url('/public/background-ia.webp');
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  filter: blur(12px);
  z-index: -1;
}

/* AppContainer */

.AppContainer {
  display: flex;
}

/* Sidebar */

.sidebar-container {
  display: flex;
  width: 350px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 20px;
  justify-content: space-between;
}

.profile-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 50px;
  border: 7px solid #9CDCFE;
}

.name {
  color: #9CDCFE;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 50px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 50px;
  align-items: center;
}

.menu-item {
  color: #D4D4D4;
  font-size: 1.5em;
  text-decoration: none;
  transition: color 0.3s;
}

.menu-item:hover {
  transform: scale(1.2);
  color: #CE9178;
}

.menu-item.active {
  font-weight: bold;
  color: #CE9178;
}

.social-links {
  display: flex;
  gap: 25px;
}

.social-btn {
  color: #D4D4D4;
  font-size: 60px;
  transition: transform 0.3s;
}

.social-btn:hover {
  transform: scale(1.2);
  color: #CE9178;
}

/* Content */

.ContentContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
}

.title {
  font-size: 2em;
  color: #9CDCFE;
}

/* Home */

.CodeBlock {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 30px;
  font-size: 2em;
  color: white;
  margin: 0 auto;
}

.CustomBlue {
  color: #9CDCFE;
}

.CustomGrey {
  color: #D4D4D4;
}

.CustomYellow {
  color: #CE9178;
}

.CustomGreen {
  color: #B5CEA8;
}

/* Contact */

.BackBlock {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 30px;
  font-size: 1.3em;
  color: white;
  margin: 0 auto;
}

.link {
  color: #B5CEA8;
  font-size: 1.2em;
  text-decoration: none;
  margin: 8px 0;
}

.email {
  color: #B5CEA8;
  cursor: pointer;
  font-size: 1.2em;
  background: none;
  border: none;
}

.email:hover, .link:hover {
  color: #9CDCFE;
}

.icon {
  font-size: 1.5em;
}
